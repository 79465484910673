<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 500 : 900"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    class="slideout-deep-purple-header"
    :visible.sync="slideouts.review.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Shift Request ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1 d-flex align-center" small>
        <span> <i class="fad fa-arrows-turn-right mr-2"></i>Review: {{ selected.name }} </span>
        <resource-shift-request-state
          class="ml-2"
          :state="selected.state"
        ></resource-shift-request-state>
      </h3>
      <div>
        <panel-size-control dark v-model="slideouts.review.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon dark>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Shift Request ...</p>
    </v-container>

    <v-form v-else v-model="slideouts.review.valid" ref="updateForm">
      <v-container v-if="slideouts.review.active" fluid class="pa-4">
        <v-row style="position: sticky; top: 0; z-index: 1">
          <v-col style="width: 100%" class="d-flex align-center pt-1">
            <label
              class="input-label d-flex align-center"
              style="flex-wrap: nowrap; white-space: nowrap"
            >
              <i class="fad fa-user mr-2"></i> Shift hours for
              <span class="opacity-64 fs-12px mx-2">"Source"</span>
            </label>
            <user-all-selector
              :users="users"
              required
              hideLabel
              hideDetails
              readonly
              :isUsersLoading="isUsersLoading"
              v-model="selected.sourceUserId"
              style="width: 100%"
              class="ml-0 white"
            ></user-all-selector>
          </v-col>
          <v-col cols="12" md="12" class="mb-4">
            <rich-text-editor
              ref="richTextEditor"
              v-model="selected.justification"
              title="Shift Request Justification<i class='fas fa-star-of-life pink--text label-icon'></i>"
              showLabel
              allowExpand
              readonly
            />
          </v-col>
        </v-row>
        <div class="mt-5 mb-0 d-flex flex-row justify-space-between">
          <label class="input-label">
            <i class="fad fa-clock mr-2"></i>Shift Request Hours
            <v-chip
              color="secondary"
              dark
              class="ml-2 font-weight-bold"
              small
              label
              style="height: 20px"
              >{{ totalShiftedHours }}
            </v-chip>
          </label>
        </div>
        <div
          class="pa-5 inset-shadow mt-2"
          style="
            background: #eceff1;
            border-radius: 0.5rem;
            overflow: auto;
            overflow: overlay;
            height: 100%;
          "
        >
          <v-slide-y-reverse-transition mode="out-in" style="width: 100%">
            <div
              v-if="selected.shiftRecords.length == 0"
              key="empty"
              style="gap: 1rem"
              class="d-flex align-center justify-center flex-column"
            >
              <i class="fad fa-horse-saddle fa-swap-opacity" style="font-size: 7rem"></i>
              <h4>Nothing to shift requestyet!</h4>
            </div>
            <v-slide-y-transition
              v-else
              key="not-empty"
              mode="out-in"
              group
              style="width: 100%; gap: 1rem"
              class="d-flex flex-column"
            >
              <shift-request-record-card
                v-for="(shiftRequestRecord, i) in selected.shiftRecords"
                :key="'sr_' + i"
                :value="selected.shiftRecords[i]"
                readonly
                fullWidth
              ></shift-request-record-card>
            </v-slide-y-transition>
          </v-slide-y-reverse-transition>
        </div>
      </v-container>
    </v-form>

    <v-dialog v-model="modals.reject.active" max-width="800px" persistent style="z-index: 99999999">
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <span class="fs-16px">
            <i class="fad fa-power-off mr-2"></i>Reject Shift Request: <b>{{ selected.name }}</b>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.reject.valid" ref="rejectShiftRequestForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.reject.note"
                    title="Rejection Note<i class='fas fa-star-of-life pink--text label-icon' style='line-height: 20px'></i>"
                    showLabel
                    allowExpand
                  />
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="rejectShiftRequestDiscard()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="pink"
              :dark="validRejectNotes && !modals.reject.loading"
              :disabled="!validRejectNotes || modals.reject.loading"
              :loading="modals.reject.loading"
              @click="rejectShiftRequestConfirmed()"
            >
              <i class="far fa-power-off mr-2"></i>
              Confirm Rejection
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modals.approve.active"
      max-width="800px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <span class="fs-16px">
            <i class="fad fa-check-double mr-2"></i>Approve Shift Request:
            <b>{{ selected.name }}</b>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.approve.valid" ref="approveShiftRequestForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.approve.note"
                    title="Approval Note<i class='fas fa-star-of-life pink--text label-icon' style='line-height: 20px'></i>"
                    showLabel
                    allowExpand
                  />
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="approveShiftRequestDiscard()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="cyan"
              :dark="validApproveNotes && !modals.approve.loading"
              :disabled="!validApproveNotes || modals.approve.loading"
              :loading="modals.approve.loading"
              @click="approveShiftRequestConfirmed()"
            >
              <i class="far fa-check-double mr-2"></i>
              Confirm Approval
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <template v-slot:footer>
      <v-card-actions>
        <v-btn
          v-if="!isFetching && selected.id != null"
          :disabled="slideouts.review.isLoading"
          @click="closeUpdateSlideout"
          color="secondary"
          text
        >
          <i class="fal fa-xmark mr-2"></i> Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <div class="d-flex ml-2" v-if="!isFetching && $has(perms.ResourceShiftRequests.Update)">
          <div class="d-flex ml-2" v-if="!isFetching && canApprove">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="cyan"
                  dark
                  :loading="slideouts.review.isApproving"
                  :disabled="slideouts.review.isLoading"
                  @click="approveShiftRequest"
                >
                  <i class="far fa-check-double mr-2"></i>
                  Approve Shift Request
                </v-btn>
              </template>
              <span class="d-flex align-center">
                Approve shift request and proceed to shift the hours!
              </span>
            </v-tooltip>
          </div>

          <div class="d-flex ml-2" v-if="!isFetching && canReject">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="pink"
                  dark
                  :loading="slideouts.review.isRejecting"
                  :disabled="slideouts.review.isLoading"
                  @click="rejectShiftRequest"
                >
                  <i class="far fa-power-off mr-2"></i>
                  Reject Shift Request
                </v-btn>
              </template>
              <span class="d-flex align-center">Reject Shift Request</span>
            </v-tooltip>
          </div>

          <v-divider vertical class="mx-2"></v-divider>
          <div class="d-flex ml-2" v-if="selected.id != null">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="2"
                  width="32px"
                  height="32px"
                  min-width="32px"
                  min-height="32px"
                  :loading="isFetching"
                  :disabled="slideouts.review.isLoading || isFetching"
                  @click="fetchShiftRequest()"
                >
                  <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
                </v-btn>
              </template>
              <span>Refetch Shift Request</span>
            </v-tooltip>
          </div>
        </div>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import shiftRequestsAPI from "../services/resource-shift-requests-service";
import ResourceShiftRequestState from "./ResourceShiftRequestState.vue";
import ShiftRequestRecordCard from "./ShiftRequestRecordCard.vue";
import usersAPI from "../../Admin/services/StaffService";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";
import enums from "../../../plugins/enums";
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";

export default {
  name: "review-shift-request",
  components: {
    PanelSizeControl,
    UserAllSelector,
    ShiftRequestRecordCard,
    ResourceShiftRequestState,
    RichTextEditor,
  },
  data() {
    return {
      perms: perms,
      shiftRequestId: null,
      isFetching: false,
      isUsersLoading: false,
      users: [],
      selected: {},
      selectedCemented: {},
      slideouts: {
        review: {
          valid: false,
          active: false,
          fullWidth: false,
          isLoading: false,
          isAccepting: false,
          isDeclining: false,
          isApproving: false,
          isRejecting: false,
          isClosing: false,
        },
      },
      modals: {
        reject: {
          active: false,
          valid: false,
          loading: false,
          note: null,
        },
        approve: {
          active: false,
          valid: false,
          loading: false,
          note: null,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    managementMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.review.fullWidth ? "100%" : this.nested ? "87%" : "1000px";
    },
    shiftRequestsUrl() {
      if (this.selected == null) return "";
      return `manage-shift-requests/${this.selected.id}`;
    },
    totalShiftedHours() {
      if (!this.slideouts.review.active || this.isFetching) return 0;
      else
        return this.selected.shiftRecords.reduce((total, cur) => {
          return total + cur.shiftedHours;
        }, 0);
    },
    canApprove() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.$has(perms.ResourceShiftRequests.Approve) &&
        this.managementMode &&
        this.selected.state == enums.ResourceShiftRequestState.PendingManagement.value
      );
    },
    canReject() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.$has(perms.ResourceShiftRequests.Reject) &&
        this.managementMode &&
        this.selected.state == enums.ResourceShiftRequestState.PendingManagement.value
      );
    },
    validApproveNotes() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.modals.approve.note != null &&
        this.modals.approve.note.trim() != "<p></p>" &&
        this.modals.approve.note.trim() != ""
      );
    },
    validRejectNotes() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.modals.reject.note != null &&
        this.modals.reject.note.trim() != "<p></p>" &&
        this.modals.reject.note.trim() != ""
      );
    },
  },
  created() {
    this.getAllUsersData();
  },
  methods: {
    getAllUsersData() {
      this.isUsersLoading = true;
      usersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    onSlideoutClosing(e) {
      //reset the changes
      this.selected = {};
      this.$emit("close");
    },
    closeUpdateSlideout() {
      this.slideouts.review.active = false;
    },
    open(id) {
      this.$log(">>>>>>> open", id);
      this.shiftRequestId = id;
      this.fetchShiftRequest();
      this.slideouts.review.active = true;
    },
    fetchShiftRequest() {
      this.isFetching = true;
      shiftRequestsAPI
        .getById(this.shiftRequestId)
        .then((resp) => {
          this.$log("getShiftRequestById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.review.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          for (let i = 0; i < this.selected.shiftRecords.length; i++) {
            const shiftRecord = this.selected.shiftRecords[i];
            shiftRecord.shiftedToDate = shiftRecord.shiftedToDate.substring(0, 7);
          }
          if (this.isMainRoute) document.title = this.selected.name + " | Concordia";
        })
        .catch((err) => {
          this.$log("getShiftRequestById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    approveShiftRequest() {
      this.modals.approve.note = null;
      this.modals.approve.loading = false;
      this.modals.approve.active = true;
    },
    approveShiftRequestConfirmed() {
      this.modals.approve.loading = true;
      shiftRequestsAPI
        .approveShiftRequest({
          id: this.selected.id,
          note: this.modals.approve.note,
        })
        .then((resp) => {
          this.modals.approve.loading = false;
          this.onApproveShiftRequestSuccess(this.selected.id);
          this.approveShiftRequestDiscard();
          this.$dialog.notify.success(`Shift Request <b>'${this.selected.name}'</b> Approved!`, {
            position: "top-right",
            timeout: 3000,
          });
          this.closeUpdateSlideout();
        })
        .catch((resp) => {
          this.modals.approve.loading = false;
          this.$dialog.notify.error("Error approving the shift request!", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    approveShiftRequestDiscard() {
      this.modals.approve.note = null;
      this.modals.approve.loading = false;
      this.modals.approve.active = false;
    },
    onApproveShiftRequestSuccess(id) {
      this.$emit("approve", id);
    },
    rejectShiftRequest() {
      this.modals.reject.note = null;
      this.modals.reject.loading = false;
      this.modals.reject.active = true;
    },
    rejectShiftRequestConfirmed() {
      this.modals.reject.loading = true;
      shiftRequestsAPI
        .rejectShiftRequest({
          id: this.selected.id,
          note: this.modals.reject.note,
        })
        .then((resp) => {
          this.modals.reject.loading = false;
          this.onRejectShiftRequestSuccess(this.selected.id);
          this.rejectShiftRequestDiscard();
          this.$dialog.notify.success(`Shift Request <b>'${this.selected.name}'</b> Rejected!`, {
            position: "top-right",
            timeout: 3000,
          });
          this.closeUpdateSlideout();
        })
        .catch((resp) => {
          this.modals.reject.loading = false;
          this.$dialog.notify.error("Error approving the shift request!", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    rejectShiftRequestDiscard() {
      this.modals.reject.note = null;
      this.modals.reject.loading = false;
      this.modals.reject.active = false;
    },
    onRejectShiftRequestSuccess(id) {
      this.$emit("reject", id);
    },
  },
};
</script>
